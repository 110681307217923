<template>
  <div>
    <iframe
      class="container-item"
      ref="api-frame"
      allow="autoplay; fullscreen; vr"
      allowvr
      allowfullscreen
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    ></iframe>
    <div class="controllers">
      <i class="bx bx-left-arrow" @click="goBack()"></i>
      <i class="bx bx-right-arrow" @click="goNext()"></i>
      <i class="bx bx-export" @click="addThreeDToNote()"></i>
    </div>
  </div>
</template>

<script>
import Sketchfab from '@sketchfab/viewer-api';
import moment from 'moment';

export default {
  name: 'ThreeDModelViewer',
  components: {},
  data() {
    return {
      modelData: null,
      modelIndex: 0,
    };
  },
  methods: {
    goNext() {
      this.modelIndex += 1;
      this.updateModel(this.modelData, this.modelIndex);
    },
    goBack() {
      this.modelIndex = (this.modelIndex - 1 + this.modelData.results.length)
        % this.modelData.results.length;
      this.updateModel(this.modelData, this.modelIndex);
    },
    addThreeDToNote() {
      const modelUid = this.modelData.results[this.modelIndex].uid;
      const modelName = this.modelData.results[this.modelIndex].name;

      // eslint-disable-next-line
      const value = {
        note: `<iframe
        title="Gateway Of India - Mumbai"
        frameborder="0"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay;fullscreen;xr-spatial-tracking"
        xr-spatial-tracking execution-while-out-of-viewport
        execution-while-not-rendered
        web-share 
        src="https://sketchfab.com/models/${modelUid}/embed"></iframe>`,
        noteUrl: `https://sketchfab.com/models/${modelUid}`,
        type: '3d',
        label: modelName,
        timestamp: moment().format(),
      };

      const actor = {
        username: this.$store.state.auth.user.username,
        email: this.$store.state.auth.user.email,
      };

      const notesDataPayload = {
        actor,
        value,
        dataSource: 'tj-client',
      };

      this.$store.dispatch('addToNote', notesDataPayload);
    },
    updateModel(modelData) {
      const model = modelData.results[this.modelIndex].uid;
      const iframe = this.$refs['api-frame'];
      const version = '1.10.1';
      const client = new Sketchfab(version, iframe);
      client.init(model, {
        success: function onSuccess(api) {
          api.start();
          api.addEventListener('viewerready', () => {});
        },
        error: function onError() {},
      });
    },
  },
  created() {
    this.$highway.singleDispatcher('fetchThreeDModel');
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_THREEDMODELDATA') {
        this.modelData = state.threeDModelData;
      }
    });
  },
  watch: {
    modelData(newModelData) {
      this.updateModel(newModelData);
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
iframe {
  min-height: 400px;
  width: 100%;
}
</style>
